import {Directive, HostListener, inject, Input} from '@angular/core';
import {AnalyticsService} from '../../application/analytics.service';

@Directive({
  selector: '[trackEvent]',
  standalone: true
})
export class TrackEventDirective {
  @Input('trackEvent') eventName!: string; // Ім'я події
  @Input() eventParams?: any; // Додаткові параметри

  private readonly analyticsService = inject(AnalyticsService);

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.analyticsService.logEvent({
      name: this.eventName,
      category: 'interaction',
      label: (event.target as HTMLElement).tagName,
      ...this.eventParams,
      timestamp: new Date()
    });
  }
}
