import {inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Analytics, logEvent} from "@angular/fire/analytics";
import {isPlatformServer} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  readonly #analytics = inject(Analytics);
  readonly #platformId = inject(PLATFORM_ID);

  public logEvent(eventName: string, eventParams?: Record<string, unknown>): void {

    if (isPlatformServer(this.#platformId)) {
      return;
    }

    logEvent(this.#analytics, eventName, eventParams);

  }

}
